export default function showToast(text) {
  if (document.querySelector('.toast')) {
    return;
  }

  const toastEl = document.createElement('div');

  toastEl.classList.add('toast');
  toastEl.innerText = text;

  document.body.appendChild(toastEl);

  setTimeout(() => {
    toastEl.classList.add('toast--show');
  }, 100);

  setTimeout(() => {
    toastEl.classList.remove('toast--show');
  }, 3000);

  setTimeout(() => {
    toastEl.remove();
  }, 3500);
}
