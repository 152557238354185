import showToast from './toast';

const copyUrlBtns = document.querySelectorAll('.btn-social-copy-url');

async function copyUrl(event) {
  const toastText = event.currentTarget.getAttribute('toast-text');
  const url = window.location.href;

  await navigator.clipboard.writeText(url);

  toastText && showToast(toastText);
}

copyUrlBtns.length && copyUrlBtns.forEach(copyUrlBtn => copyUrlBtn.addEventListener('click', copyUrl));
