const formSubmitBtn = document.querySelector('#contact-form-submit');
const formSuccessMessage = document.querySelector(
  '#contact-form-validation-message-success'
);
const formErrorMessage = document.querySelector(
  '#contact-form-validation-message-error'
);
const nameInput = document.querySelector('#contact-form-name');
const emailInput = document.querySelector('#contact-form-email');
const messageInput = document.querySelector('#contact-form-message');

function getNextSibling(element) {
  let next = element.nextSibling;

  while (next && next.nodeType != 1) {
    next = next.nextSibling;
  }

  return next;
}

function handleErrorVisibility(errorElement, show) {
  const errorVisibilityClass = 'contact-form-element__error--is-visible';

  show
    ? errorElement.classList.add(errorVisibilityClass)
    : errorElement.classList.remove(errorVisibilityClass);
}

function validateFormElements(formElements) {
  const validityStatus = [];

  for (const formElement of formElements) {
    const errorElement = getNextSibling(formElement);
    const { valid } = formElement.validity;

    if (!valid) {
      handleErrorVisibility(errorElement, true);
      validityStatus.push(false);
    } else {
      handleErrorVisibility(errorElement, false);
      validityStatus.push(true);
    }
  }

  return validityStatus.every(element => element === true);
}

function validateElementOnInput(event) {
  const element = event.target;

  validateFormElements([element]);
}

function resetFormElements(formElements) {
  formElements.forEach(formElement => {
    if (formElement.value) {
      formElement.value = '';
    }
  });
}

// Handle input on required form fields to clear obsolete errors
nameInput && nameInput.addEventListener('input', validateElementOnInput);
emailInput && emailInput.addEventListener('input', validateElementOnInput);
messageInput && messageInput.addEventListener('input', validateElementOnInput);

// Handle click on contact form submit
formSubmitBtn &&
  formSubmitBtn.addEventListener('click', async event => {
    event.preventDefault();

    const form = document.querySelector('#contact-form');
    const name = form.elements['name'];
    const email = form.elements['email'];
    const phone = form.elements['phone'];
    const company = form.elements['company'];
    const message = form.elements['message'];

    const isFormValid = validateFormElements([name, email, message]);

    if (!isFormValid) {
      return;
    }

    try {
      const response = await fetch('https://api.codekultur.com', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: name.value.trim(),
          email: email.value.trim(),
          phone: phone.value.trim(),
          company: company.value.trim(),
          message: message.value.trim()
        })
      });

      if (!response.ok) {
        throw new Error(response.status);
      }

      resetFormElements([name, email, phone, company, message]);

      // Handle success/error messages
      formErrorMessage.classList.remove(
        'contact-form-validation__message--is-visible'
      );
      formSuccessMessage.classList.add(
        'contact-form-validation__message--is-visible'
      );
    } catch (error) {
      console.error(
        `There was an issue submitting the form. Error status: ${error.message}.`
      );

      // Handle success/error messages
      formSuccessMessage.classList.remove(
        'contact-form-validation__message--is-visible'
      );
      formErrorMessage.classList.add(
        'contact-form-validation__message--is-visible'
      );
    } finally {
      setTimeout(() => {
        formSuccessMessage.classList.remove(
          'contact-form-validation__message--is-visible'
        );
        formErrorMessage.classList.remove(
          'contact-form-validation__message--is-visible'
        );
      }, 5000);
    }
  });
