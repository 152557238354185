const labels = document.querySelectorAll('.tech-stack-tab');
const panels = document.querySelectorAll('.tech-stack-panel');

labels.forEach(label => {
  label.addEventListener('click', (event) => {
    const targetId = event.target.dataset.id;
    const panelToActivate = [...panels].find(panel =>panel.dataset.id === targetId);

    panels.forEach(panel => panel.classList.remove('tech-stack-panel--is-active'));
    panelToActivate.classList.add('tech-stack-panel--is-active');
  });
});
