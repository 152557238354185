import './contactForm';
import './copyUrl';
import './header';
import './languageSwitcher';
import './scrollTop';
import './surprise';
import './techStack';

const addressTrigger = document.querySelector('#address-trigger');

if (addressTrigger) {
  addressTrigger.addEventListener('click', () => {
    addressTrigger.classList.toggle('home-contact__pin--active');
  });
}
