function runOnScroll() {
  const element = document.querySelector('body');
  const elementVerticalPosition = element.getBoundingClientRect().y;

  if (elementVerticalPosition < -10) {
    if (
      !document.getElementById('header').classList.contains('header--detached')
    ) {
      document.getElementById('header').classList.add('header--detached');
    }
  } else {
    if (
      document.getElementById('header').classList.contains('header--detached')
    ) {
      document.getElementById('header').classList.remove('header--detached');
    }
  }
}

runOnScroll();

window.addEventListener('scroll', () => runOnScroll(), { passive: true });

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// Handle click on menu trigger
function menuTriggerHandler() {
  document.querySelector('body').classList.toggle('is-scroll-lock');
  document.querySelector('#backdrop').classList.toggle('header-nav-backdrop--show');
  document.querySelector('#header-nav').classList.toggle('header-nav--show');
  document.querySelector('#menu-trigger').classList.toggle('header-nav-trigger--is-active');
}

document.querySelector('#backdrop').addEventListener('click', menuTriggerHandler);
document.querySelector('#menu-trigger').addEventListener('click', menuTriggerHandler);
